 /deep/ .upload-btn_1 .el-upload {
  height: 40px !important;
  border: none !important;
}
.dictionaryKey /deep/ .el-form-item__content {
  display: flex;
  justify-content: flex-start;
  height: 340px;
}
.dictionaryKey /deep/ .el-form-item__content .dictionaryKey_1 {
  height: 100%;
  overflow-y: auto;
}
.dictionaryKey /deep/ .el-form-item__content .dictionaryKey_1 .el-radio {
  display: block;
}
.dictionaryKey /deep/ .el-form-item__content .dictionaryKey_2 {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100px;
}
.dictionaryKey /deep/ .el-form-item__content .dictionaryKey_3 {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}
.dictionaryKey /deep/ .el-form-item__content .dictionaryKey_3 p {
  line-height: 24px;
}
